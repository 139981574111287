import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const IndexPage = () => (
  <Layout className='IndexPage'>
    <header className='HomeHeader'>
      <h1 className='HomeHeader__title'>
        Collin <span>Waid</span>
      </h1>
      <h2 className='HomeHeader__subtitle'>
        Front-end Developer
      </h2>
      <p className='HomeHeader__experience'>11+ years of professional experience</p>
    </header>
    <div>
      <p>
        Hello, I’m Collin.
        <br/><br/>
        I’m a front-end developer, always excited to develop applications that help and delight others. I enjoy problem solving, from the high level to the nitty gritty of why my HTML5 video won’t play in Safari on iOS. (Ah-ha, my host didn’t support <span className='nowrap'>byte-range</span> requests. Fixed!)
      </p>
      <p>
        Welcome to my portfolio, where you will find an expanded version of my resume that includes more details, recommendations, and examples of my work. The <span className='nowrap'>front-end</span> development for all examples presented will have been done primarily by me, but also encompass the excellent work of various teams of designers and engineers.
      </p>
      <Link to='/portfolio/' className='ActionBtn'>View Portfolio</Link>
      <p>
        I built this website with <OutboundLink href='https://www.gatsbyjs.org/' target='_blank' rel='noopener noreferrer'>Gatsby</OutboundLink>, a <OutboundLink href='https://reactjs.org/' target='_blank' rel='noopener noreferrer'>React</OutboundLink> based framework.
      </p>
      <p>
        If you’d like to get in touch, my <Link to='/contact/'>contact</Link> information is available.
      </p>
    </div>
  </Layout>
)

export default IndexPage
